import foodgiven from '../assets/foodgiven.jpg'
import ifterparty from '../assets/ifterparty.jpg'
import ifterparty2 from '../assets/ifterparty2.jpg'
import ifterparty4 from '../assets/ifterparty4.jpg'

import miladmaufil from '../assets/miladmaufil.jpg'
import miladmaufil2 from '../assets/miladmaufil2.jpg'
import picnic1 from '../assets/picnicGroup1.jpg'
import picnic2 from '../assets/picnicGroup2.jpg'

import pricegiven1 from '../assets/pricegiven1.jpg'
import pricegiven2 from '../assets/pricegiven2.jpg'
import pricegiven3 from '../assets/pricegiven3.jpg'
import pricegiven4 from '../assets/pricegiven4.jpg'
import pricegiven5 from '../assets/pricegiven5.jpg'
import pricegiven6 from '../assets/pricegiven6.jpg'
import pricegiven7 from '../assets/pricegiven7.jpg'
import pricegiven8 from '../assets/pricegiven8.jpg'
import pricegiven9 from '../assets/pricegiven9.jpg'
import pricegiven10 from '../assets/pricegiven10.jpg'
import pricegiven11 from '../assets/pricegiven11.jpg'
import pricegiven12 from '../assets/pricegiven12.jpg'
import pricegiven13 from '../assets/pricegiven13.jpg'
import pricegiven14 from '../assets/pricegiven14.jpg'
import pricegiven15 from '../assets/pricegiven15.jpg'
import pricegiven16 from '../assets/pricegiven16.jpg'

import treeplantation from '../assets/treeplantation.jpg'
import treeplantation2 from '../assets/treeplantation2.jpg'
import treeplantation3 from '../assets/treeplantation3.jpg'
import treeplantation4 from '../assets/treeplantation4.jpg'
import treeplantation5 from '../assets/treeplantation5.jpg'

// RECENT IMAGE COLLECTION
import coz1 from '../assets/COZ1.jpg';
import coz2 from '../assets/COZ2.jpg';
import coz3 from '../assets/COZ3.jpg';

// IMAGE FROM 2023 PICNIC
import cricket1 from '../assets/2023/cricket1.jpg'
import cricket2 from '../assets/2023/cricket2.jpg'
import cricket3 from '../assets/2023/cricket3.jpg'
import pic_2023_1 from '../assets/2023/pic_2023_1.jpg'
import pic_2023_2 from '../assets/2023/pic_2023_2.jpg'
import pic_2023_3 from '../assets/2023/pic_2023_3.jpg'
import pic_2023_4 from '../assets/2023/pic_2023_4.jpg'



const galleryImageArray = [
    cricket1,
    cricket2,
    cricket3,
    pic_2023_1,
    pic_2023_2,
    pic_2023_3,
    pic_2023_4,
    coz1,
    coz2,
    coz3,
    ifterparty,
    ifterparty2,
    ifterparty4,
    miladmaufil,
    miladmaufil2,
    picnic1,
    picnic2,
    pricegiven1,
    pricegiven2,
    pricegiven3,
    pricegiven4,
    pricegiven5,
    pricegiven6,
    pricegiven7,
    pricegiven8,
    pricegiven9,
    pricegiven10,
    pricegiven11,
    pricegiven12,
    pricegiven13,
    pricegiven14,
    pricegiven15,
    pricegiven16,
    treeplantation,
    treeplantation2,
    treeplantation3,
    treeplantation4,
    treeplantation5
]

export default galleryImageArray;