import React from 'react'
import ImageCollection from '../../DataBaseFile/imageData'
import Slider from "react-slick";
import call from '../../assets/animatedIcon/telephone.gif'
import emailIcon from '../../assets/animatedIcon/email.gif'


const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        fade: true,
        speed: 1000,
        pauseOnHover: false,
        autoplaySpeed: 2000,

        appendDots: dots => (
            <div
                style={{
                }}
            >
                <ul style={{ margin: "-50px" }}> {dots} </ul>
            </div>
        ),
    };
    return (
        <div className='w-full md:h-[520px] 2xl:h-[800px]  mb-14 '>

            <Slider {...settings}  >
                {
                    ImageCollection?.map(caroData =>
                        <Image
                            key={caroData.id}
                            img={caroData.img}
                            address={caroData.address}
                            location={caroData.location}
                            phn={caroData.phn}
                            email={caroData.email}
                            map={caroData.map}
                        />)
                }
            </Slider>
        </div>
    )
}

export default Carousel

const Image = ({ img, address, location, phn, email, map }) => {


    return (
        <div className='h-72 md:h-[520px] 2xl:h-[800px]  w-full relative' >

            {/* <a href={map} target='_blank' > */}
            < img src={img} className='h-full w-full object-cover 2xl:object-center' alt='slide' />
            <div className='h-full w-full bg-black absolute z-20 top-0 opacity-20' />
            {/* </a> */}

            {/* <video className='w-full h-full object-cover' src={img} autoPlay loop muted ></video>
            <div className='h-full w-full bg-black absolute z-20 top-0 opacity-20' /> */}


            {/* Text Animation*/}
            {address ? <a href={map} target='_blank' >
                <div className='rounded  absolute z-20 lg:w-1/3 right-0 top-[30%] 2xl:top-[40%] 2xl:right-[40%] bg-cyan-600 opacity-80 p-5 text-white text-2xl'>
                    <h1 className='uppercase '>{address}</h1>
                    <p className='text-sm'>{location}</p>
                    {/* {
                    location ?  */}
                    <div className='flex '>
                        <div className='flex items-center h-11 md:mr-5'>
                            <img className='w-5 rounded  mr-3 ' src={call} />
                            <a href={`tel:${phn}`} className='text-sm '> {phn}</a>
                        </div>
                        <div className='flex items-center h-11'>
                            <img className='w-5 rounded mr-3 ' src={emailIcon} />
                            <a href={`tel:${phn}`} className='text-sm '> {email}</a>
                        </div>
                    </div>
                    {/* :
                    null
                } */}

                </div>
            </a> : null}
        </div>
    )
}