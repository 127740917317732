import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import galleryImageArray from '../../DataBaseFile/galleryImageArray';

const Gallery = () => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [zindex, setZindex] = useState(0)


    const openImageViewer = useCallback((index) => {
        setZindex(50);
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setZindex(0);
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <div>
            <HeroBanner title={'Gallery'} />
            <div className='my-5'>
                <div className={`custom_container z-${zindex} grid md:grid-cols-4 grid-cols-1   gap-2 cursor-pointer`}>
                    {galleryImageArray.map((src, index) => (
                        <img
                            data-aos="fade-up"
                            src={src}
                            onClick={() => openImageViewer(index)}
                            key={index}
                            style={{ margin: '2px' }}
                            alt=""
                            className=' object-contain rounded-md'
                            loading='lazy'
                        />
                    ))}

                    {isViewerOpen && (
                        <ImageViewer
                            src={galleryImageArray}
                            currentIndex={currentImage}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}

export default Gallery