import React from 'react'

import { Link } from 'react-router-dom'

const Navitem = ({ title, submenu, downArrow, link, id }) => {

    // conditional route data 
    const routerLink = submenu ? null : `${link}/${title}`

    const dropDown = downArrow ?
        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
        :
        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>

    return (
        <li tabIndex={0}>
            <Link to={routerLink} className="justify-between text-sm uppercase hover:bg-gray-200">
                {title}
                {
                    submenu?.length ?
                        dropDown
                        :
                        null
                }

            </Link>
            <ul className="p-1 ml-2 md:ml-0 bg-white shadow ">
                {
                    submenu?.map((subItem, index) =>
                        <Link to={`${link}/${subItem}`}>
                            <li key={index}><a className='p-1 hover:bg-gray-200 capitalize'>{subItem}</a></li>
                        </Link>
                    )
                }
            </ul>
        </li>
    )
}

export default Navitem