import baffa from '../assets/Certificate/BAFFA.jpg'
import fiata from '../assets/Certificate/FIATA.jpg'
import g7n from '../assets/Certificate/G7N.jpg'
import jctrans from '../assets/Certificate/JCTRANS.jpg'
import wca from '../assets/Certificate/WCA.jpg'
import prolog from '../assets/Certificate/prolog.jpg'
import fmc from '../assets/Certificate/Fmc.png'


const certificateData = [
    g7n,
    jctrans,
    wca,
    fiata,
    prolog,
    baffa,
    fmc,
]


export default certificateData;