const menuData = [
    {
        id: 0,
        title: 'corporate',
        link: '/details',
        submenu: ['Chairperson’s Message', 'History & Growth', 'strategy', 'core values and vision', 'company policies', 'career',]
    },
    {
        id: 1,
        title: 'Services',
        link: '/details',
        submenu: ['Freight Forwarding', 'Contact Logistics', 'LTL-FTL', 'Project Cargo', 'Reefer Cargo', 'Dangerous Cargo', 'Supply Chain Logistics', 'Value Added Service', 'Facilities and Amenities', 'Customs Clearance', 'Warehousing', 'Insurance']
    },
    {
        id: 2,
        title: 'INDUSTRY',
        link: '/details',
        submenu: ['Fashion Industry', 'Perishable Industry', 'Healthcare Industry', 'Leather Industry',
            'Food & Beverage Industry', 'Furniture Industry', 'Plastic & Rubber Industry', 'Jute Industry', 'Retail Industry']
    },
    {
        id: 3,
        title: 'SOCIAL RESPONSIBLITY',
        link: '/details',
        submenu: ['Cancer', 'Tree Plantation(TP)',
            'Income Generating Activities (IGA)', 'Urban Beautification(UB)', 'Disaster Management',
            'Scholarship and Stipends(SS)', 'Sponsor of Sports']
    },
    {
        id: 4,
        title: 'Photo Gallery',
        link: '/gallery',
    },
    {
        id: 5,
        title: 'Contact',
        link: '/contact',
    },
]

export default menuData;