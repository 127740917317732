import React, { useEffect } from 'react'
import Carousel from '../../components/Carousel/Carousel';
import Certificate from '../../components/Certificate/Certificate';
import HistoryGrowth from '../../components/HistoryGrowth/HistoryGrowth';
import MemberOf from '../../components/MemberOf/MemberOf';
import SampleContent from '../../components/SampleContent/SampleContent';
import Welcome from '../../components/Welcome/Welcome';


export const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Carousel />
            <Welcome />
            <HistoryGrowth />
            <SampleContent />
            <MemberOf />
            <Certificate />
        </div>
    )
}
