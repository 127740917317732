import React from 'react'
import mainContent from '../../DataBaseFile/mainContentData'
import SampleCntntItem from './component/SampleCntntItem'


const SampleContent = () => {

    const random = Math.ceil(Math.random() * (mainContent.length - 6))

    const sliceData = mainContent.slice(random, (random + 6))

    return (
        <div className='custom_container'>
            <p className='text-center text-xl'>LATEST NEWS</p>
            <div className=' grid md:grid-cols-2 lg:grid-cols-3 gap-5 sm:grid-cols-2 grid-cols-1 py-10'>
                {
                    sliceData.map(item =>
                        <SampleCntntItem
                            key={item.id}
                            img={item.img}
                            title={item.title}
                            details={item.content}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default SampleContent