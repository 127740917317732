import image1 from '../assets/image1.jpg'

const WelcomeTxt = [
    {
        id: 1,
        title: 'Welcome To Demotrans',
        details: 'The world of Demotrans is always open for you all. The entirety of the stakeholders creates the feeling of a single entity. Thence we offer the pragmatic comfort for the people of the business domain. The highest feasible version of freight forwarding invites you here. State-of-the-art services await you to entertain. And you can release your insomniac tensions relying on us. In fact, we love to take care of the customers.'
    },
    {
        id: 2,
        link: 'https://www.youtube.com/watch?v=bzNAcfFW-8c',
        img: image1,
    }
]

export default WelcomeTxt;