import React from 'react'
import { Link } from 'react-router-dom'

const SampleCntntItem = ({ img, title, details }) => {
    return (
        <Link to={`details/${title}`}
            data-aos="fade-up" className='p-3 hover:bg-white py-5 hover:drop-shadow-xl rounded-md cursor-pointer overflow-hidden'  >
            <div className='w-full md:h-52 lg:h-60 h-44 overflow-hidden rounded-md'>
                <img src={img} alt='news' className='hover:scale-125 transition-all duration-200 ' />
            </div>
            <p className='font-semibold text-xl py-3'>{title}</p>
            <p dangerouslySetInnerHTML={{ __html: details.slice(0, 200) }}></p>
        </Link>
    )
}

export default SampleCntntItem