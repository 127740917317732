import React from 'react'

const LocationItem = ({ address, title, phone, email }) => {
    return (
        <div className='p-5 text-center w-full h-full flex justify-center text-white' style={{ backgroundColor: '#0496D1' }}>
            <div>
                <p className='font-bold uppercase'>{title}</p>
                <div className='mt-2'>
                    <p className='text-sm'>{address}</p>
                    <p className='text-sm'>Phone:  <a href={`tel: ${phone}`}>{phone}</a></p>
                    <p className='text-sm'>email:<a href={`mailto: ${email}`}>{email}</a></p>
                </div>
            </div>
        </div>
    )
}

export default LocationItem