import React from 'react'
import { Link } from 'react-router-dom'
import LOGO from '../../assets/DEMO_LOGO.png'

import Data from '../../DataBaseFile/menuData'
import Navitem from './components/Navitem'


const Navbar = () => {

    return (
        <div className='w-full shadow-md sticky top-0 z-50 bg-white'>
            <div className="navbar bg-base-100 custom_container  justify-between">
                <div className="navbar-start  w-full justify-between md:justify-start sm:justify-between">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>

                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 
                        shadow bg-base-100 rounded-box w-52">
                            {
                                Data.map(navItem =>
                                    <Navitem
                                        key={navItem.id}
                                        title={navItem.title}
                                        submenu={navItem.submenu}
                                        link={navItem.link}
                                    />
                                )
                            }
                        </ul>
                    </div>
                    <div><Link to='/' className="btn btn-ghost normal-case text-xl"> <img src={LOGO} className='w-full h-full' alt='demotrans' /> </Link></div>
                </div>


                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal p-0 z-10">
                        {
                            Data.map(navItem =>
                                <Navitem
                                    key={navItem.id}
                                    title={navItem.title}
                                    downArrow
                                    submenu={navItem.submenu}
                                    link={navItem.link}
                                    id={navItem.id}
                                />
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar