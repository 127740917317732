import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import certificateData from '../../DataBaseFile/certificateData'

const Certificate = () => {


    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [zindex, setZindex] = useState(0)


    const openImageViewer = useCallback((index) => {
        setZindex(50);
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setZindex(0);
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <div className='custom_container '>
            <p className='text-center text-xl  py-3'>OUR CERTIFICATE</p>
            <div className='my-10'>
                <div className={`custom_container z-${zindex}  grid md:grid-cols-3 grid-cols-1   gap-10 cursor-pointer`}>
                    {certificateData.map((src, index) => (
                        <img
                            data-aos="fade-up"
                            src={src}
                            onClick={() => openImageViewer(index)}
                            key={index}
                            style={{ margin: '2px' }}
                            alt="certificate"
                            className=' object-contain rounded-md border-4 '
                            loading='lazy'
                        />
                    ))}

                    {isViewerOpen && (
                        <ImageViewer
                            src={certificateData}
                            currentIndex={currentImage}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Certificate