import image1 from '../assets/cargo2.jpeg'
import image2 from '../assets/Image2.jpeg'
import image3 from '../assets/Image3.jpg'
import image4 from '../assets/Image4.jpg'

const ImageCollection = [
    {
        id: 2,
        img: image3,

        address: 'Demotrans Bangladesh (HQ)',
        location: 'House 10, Road 10,Sector 04, Uttara , Dhaka - 1230 ',
        phn: "+880 1321123945",
        email: 'babu@demogroup.net',
        map: 'https://www.google.com/maps/place/Demotrans/@23.862946,90.39985,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c59c88a45e61:0x7d21bc7aadf3ba53!8m2!3d23.8629411!4d90.4024249!16s%2Fg%2F11h_t9bb06'
    },



    {
        id: 3,
        img: image4,
        address: 'Demotrans (CAMBODIA) CO.LTD ',
        location: `SP Building, No 48 Street 289, 2B (2nd floor), Beoung Kok II, Toul Kork, 
Phnom Penh-120408, Cambodia`,
        phn: "+855 966030809",
        email: 'sharif@demogroup.net',
        map: 'https://www.google.com/maps/place/OMOHUB/@11.5765483,104.8964531,19z/data=!4m6!3m5!1s0x3109511e52108641:0xd4444d8790e3faba!8m2!3d11.5765243!4d104.8967039!16s%2Fg%2F11j0jfq0v_'
    },


    {
        id: 4,
        img: image1,
        // address: 'Demotrans (LOGISTICS)L.L.C',
        // location: '205, 117 - 499, Abdulla Building 9, Al Mararr, Deira, Dubai, UAE.',
        // phn: "+971582663157",
        // email: 'info@demogroup.net'
    },
]

export default ImageCollection;