const locationData = [
    {
        id: 1,
        title: 'corporate office',
        address: 'House- 10, Road-10, Sector-04 Uttara, Dhaka- 1213, Bangladesh.',
        phone: `+88-02-48961424, 
                 +88-02-48961425.`,
        email: ' info@demogroup.net'
    },
    {
        id: 2,
        title: 'Registered office:',
        address: 'Plot no- 89, Sayed Ground Centre Sector: 07, Uttara, Dhaka- 1230, Bangladesh',
        phone: '+88 - 02 - 48961425',
        email: ' info@demogroup.net'
    },
    {
        id: 3,
        title: 'Chittagong office',
        address: '1722|(3rd Floor) Sk Mujib Road, Chowmuhuni Agrabad, Chittagong, Bangladesh.',
        phone: '+88-031-2515288.',
        email: ' info@demogroup.net'
    },
    {
        id: 4,
        title: 'Airport office',
        address: 'House-242, Road-01, Ward-05 Azampur, Uttara, Dhaka – 1230',
        phone: '+88-01708552250',
        email: ' info@demogroup.net'
    },
    {
        id: 5,
        title: 'Khulna office',
        address: 'Plot-80, Road-22 Jame Mosque Lane, Nirala Khulna, Bangladesh.',
        phone: ' +88-01708552249',
        email: ' info@demogroup.net'
    },
]


export default locationData;