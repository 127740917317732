import baffa from '../assets/baffa.png'
import fiata from '../assets/fiata.svg'
import jctrans from '../assets/jctrans.png'
import wcaworld from '../assets/wcaworld.png'
import g7nlogo from '../assets/G7Nlogo.jpg'
import prolognet from '../assets/prolognetmage.jpg'


const memberof = [
    {
        id: 1,
        img: baffa,
        link: 'https://www.baffa-bd.org/'
    },
    {
        id: 2,
        img: fiata,
        link: 'https://fiata.org/'
    },
    {
        id: 4,
        img: wcaworld,
        link: 'https://www.wcaworld.com/Home'
    },
    {
        id: 3,
        img: jctrans,
        link: 'https://www.jctrans.net/'
    },
    {
        id: 4,
        img: g7nlogo,
        link: 'https://g7logisticsnetworks.com/'
    },
    {
        id: 5,
        img: prolognet,
        link: 'https://prolognet.com/'
    },

]

export default memberof;