import React from 'react'

const NavtopItem = ({ Icon, text }) => {
    return (
        <div className='flex items-center align-middle h-full mr-4' >
            <Icon className='mr-2' />
            <p>{text}</p>
        </div>
    )
}

export default NavtopItem