import React, { useState } from 'react'
import WelcomeTxt from '../../DataBaseFile/WelcomeTxt'
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';


const Welcome = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className='custom_container md:h-96  grid grid-cols-1 md:grid-cols-2 md:gap-4 align-middle'>
            {
                WelcomeTxt?.map(item => <WelcomeItem
                    key={item.id}
                    title={item.title}
                    details={item.details}
                    img={item.img}
                    openModal={openModal}
                />)
            }

            <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ecVUDrTkXX4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal>

        </div >
    )
}

export default Welcome

const WelcomeItem = ({ title, details, img, openModal }) => {
    return (
        <div className='h-[100%] py-5 '>
            < h2 className='text-2xl font-bold ' data-aos="fade-right"> {title}</h2 >
            <p className='text-xl text-justify mt-3 md:pr-5' data-aos="fade-right">{details}</p>
            <div className='relative' data-aos="fade-left">
                {img &&
                    <>
                        <img src={img} className=' h-72 w-full rounded-lg object-cover ' />
                        <div className='absolute  bg-black h-full w-full top-0 rounded-md opacity-25'></div>
                        <div
                            onClick={() => openModal()}
                            className='absolute  cursor-pointer top-[45%] left-[45%] z-auto text-white animation'>
                            <AiFillPlayCircle size={35} color='white' />
                        </div>
                    </>
                }

            </div>
        </div >
    )
}