import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import SideMenu from '../../components/SideMenu/SideMenu'
import menuData from '../../DataBaseFile/menuData'

import mainContent from '../../DataBaseFile/mainContentData'
// import { LazyLoadImage } from 'react-lazy-load-image-component';


// filter data by route name ( by url route)
const filterData = (name) => {
    let items = []
    let value = []
    let res = 0;
    for (let i = 0; i < menuData.length; i++) {
        const { submenu } = menuData[i]
        items = submenu?.map(hb => hb)
        value = items?.map(item => { if (item.toLocaleLowerCase() === name.toLocaleLowerCase()) res = i })
    }
    return res
}


const DetailsPage = () => {

    const { title } = useParams()

    const [selectText, setSelectText] = useState(title)


    //get route id and filter data 
    const result = filterData(title)

    const handleSelection = (text) => {
        setSelectText(text)
        window.scrollTo({
            top: 200,
            behavior: "smooth"
        })
    }


    useEffect(() => {
        window.scrollTo({
            top: 200,
            behavior: "smooth"
        })
        setSelectText(title)
    }, [title])


    // filter maincontent
    const filterdata = mainContent.filter(content => content.title.toLocaleLowerCase() === selectText.toLocaleLowerCase())

    //filter side menu
    const menuFilter = menuData.filter(content => content.id === result)

    return (
        <div >
            <HeroBanner title={selectText ? selectText : title} />
            <div className='custom_container '>
                <div className='my-5 grid md:grid-cols-10 gap-3 md:gap-10 grid-cols-1'>
                    <div className='col-span-8 md:col-span-3 order-last md:order-first' data-aos="fade-right">
                        {
                            menuFilter[0]?.submenu.map((submenu, index) =>
                                <SideMenu
                                    sideText={submenu}
                                    index={index}
                                    handleSelection={handleSelection}
                                    text={selectText}
                                />
                            )
                        }
                    </div>

                    <div className='md:col-span-7 col-span-8'>
                        <div className='h-60 md:h-96 w-full relative'>
                            {/* 
                            <LazyLoadImage
                                src={filterdata[0]?.img} className='w-full h-full object-cover'
                            /> */}

                            <img data-aos="fade-left" src={filterdata[0]?.img} alt="" className='w-full h-full object-cover' />
                            <div className='h-full w-full bg-black absolute z-20 top-0 opacity-20' />
                        </div>


                        <p data-aos="fade-left" className='mt-5 text-4xl border-b-2 pb-5 border-black uppercase'>{selectText}</p>

                        {/* dangerous ly show html code in jsx */}
                        <p className='mt-5 text-lg text-justify' data-aos="fade-left"
                            dangerouslySetInnerHTML={{ __html: filterdata[0]?.content }}>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DetailsPage