import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'

const SideMenu = ({ sideText, handleSelection, text }) => {

    return (
        <div className={`flex  border-b-[.5px] border-gray-400 items-center justify-between p-3 hover:bg-blue-500 
        ${text === sideText ? 'bg-blue-500 text-white' : 'bg-slate-300'} hover:text-white cursor-pointer`}
            onClick={() => handleSelection(sideText)}
        >
            <p className=' text-lg font-medium'>{sideText}</p>
            <MdKeyboardArrowRight size={25} />
        </div>
    )
}

export default SideMenu