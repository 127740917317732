import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';


const HistoryGrowth = () => {
    return (
        <div className='mt-10 mb-10 md:mt-10 md:mb-16 bg-blue-200 text-black text-justify  py-20'>
            <div className='custom_container' data-aos="zoom-in">
                <p className='text-2xl font-bold mb-4'>History & Growth</p>
                <p className='text-xl mb-4'>In line with the dream of our Chairperson, Demotrans started as worldwide logistics service provider to make hope for cancer survivors. Since its inception, Demotrans has been endeavoring to become one of the leading specialist logistics and supply chain companies in Bangladesh. By the course of time and with the flow of business operations it has proved its existence domineering overthe competitive firms by and large through advancing in high quality client portfolio across a wider range of industrial sectors both in domestic and international arena. More</p>
                <Link to='/details/History & Growth' className='text-xl mb-4 cursor-pointer  items-center inline  hover:text-yellow-500 text-blue-500'>
                    Learn More
                    <BsArrowRightShort size={30} className='ml-1 inline' />
                </Link>
            </div>
        </div>
    )
}

export default HistoryGrowth